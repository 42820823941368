import React from "react";
// import { useQuery } from '@apollo/client';
import { graphql } from "gatsby"
// import gql from "graphql-tag";
import Header from "../components/Header/Header";
import OffPlanBanner from "../components/PropertyDetails/OffPlanBanner";
import OffPlanDescription from "../components/PropertyDetails/OffPlanDescription";
import OffPlanBlocks from "../components/PropertyDetails/OffPlanBlocks";
import OffPlanGallery from "../components/PropertyDetails/OffPlanGallery";
import PropertyDetailsMap from "../components/PropertyDetails/PropertyDetailsMap";
import OffPlanMoreProjects from "../components/PropertyDetails/OffPlanMoreProjects";
import { propertyDetImage } from "../components/Seo/propertyDetailsSEO";
import BookaViewing from "../components/PropertyDetails/BookaViewing";
import Footer from "../components/Footer/Footer";
import BrandLogo from "../images/icons/logo_black.svg";
import SEO from "../components/Seo/seo"
import { capitalize } from "lodash";

export const OFFPLAN_DETAILS = graphql`
  query ($id: ID!) {
    glstrapi {
      offplans(where: { id: $id }) {
        id
        search_type
        status
        department
        publish
        price
        price_qualifier
        address
        display_address
        title
        area
        brochure
        bedroom
        bathroom
        reception
        building
        extra
        floorplan
        crm_negotiator_id
        latitude
        longitude
        description
        long_description
        images
        imagetransforms
        ggfx_results {
          transforms
          src_cftle
        }
        video_link
        virtual_tour
        banner_img {
          url
          id
        }
        upload_floorplan {
          id
          url
        }
        upload_brochure {
          id
          url
        }
      }
    }
  }
`

const OffplanDetailsTempalte = ({data}) => {


    return (
        <React.Fragment>
            <div className="off-plan-property-details-template">
                <Header layout={""} />

                {
                    data && data?.glstrapi?.offplans && data?.glstrapi.offplans.length > 0 ?
                    (
                        data.glstrapi.offplans.map((data, i) => {
                            const building = data?.building
                            var searchaction = data.search_type === "sales"?"for sale":"to let"            
                            var metatile = ""+capitalize(building ? building?.replace(/-/g, ' ') : 'property')+" "+searchaction+" with "+data.bedroom+" bedrooms in "+data.display_address+" at AED "+data.price.toLocaleString();
                            var metadesc = "Know the details of "+capitalize(building?.replace(/-/g, ' '))+" "+searchaction+" with "+data.bedroom+" bedrooms in "+data.display_address+" at AED "+data.price.toLocaleString()+". Book a viewing to get assistance in finding the right "+capitalize(building?.replace(/-/g, ' '))+" for you.";
                            var metaimg = data?.images && data?.images?.length > 0 ? propertyDetImage(data) : "";

                            return (
                                <>
                                    <SEO title={metatile} description={metadesc} image={metaimg} />

                                    {data?.ggfx_results?.length > 0 && (
                                      <OffPlanBanner offplanData={data} />
                                    )}

                                    <OffPlanDescription offplanData={data} />

                                    <OffPlanBlocks offplanData={data} />

                                    <OffPlanGallery offplanData={data} />

                                    {/* {
                                        data?.latitude && data?.longitude &&
                                        <div className="property-description-map">
                                            <PropertyDetailsMap
                                                lat={data?.latitude}
                                                lng={data?.longitude}
                                                offplanMap={"offplan-map"}
                                            />
                                        </div>
                                    } */}

                                    <OffPlanMoreProjects offplanData={data} />

                                    <BookaViewing propertyData={data} isOffplan />
                                </>
                            )
                        })
                    )
                    :
                    ''
                }

                <Footer layout={"property-details"} categoryType="category1"/>
            </div>
        </React.Fragment>
    )
}

export default OffplanDetailsTempalte